import "./pages.css";

//go here if link is invalid
const NotFound = (props) => {
    return (
        <div>
            NotFound
        </div>
    );
}

export default NotFound;