//tags used for projects
//Many are temporary, as they are just what was in the data
export const tags = [
  "New",
  "Video Game",
  "Analog Game",
  "Mobile Application",
  "Website",
  "Animation",
  "Film",
  "Software",
  "Other"
]

export const projectTypes = [
  "Video Game",
  "Analog Game",
  "Mobile Application",
  "Website",
  "Animation",
  "Film",
  "Software",
  "Other",
]

//Only used for profiles
export const softSkills = [
  "Problem Solving",
  "Creativity",
  "Leadership",
  "Organization",
  "Critical Thinking",
  "Public Speaking",
  "Team Work",
  "Interpersonal communication",
  "Intrapersonal communcation",
  "Innovation",
  "Work ethic",
  "Trustworthiness",
  "Flexibility",
  "Openness to criticism",
  "Decision-making",
  "Responsiveness",
  "Curiosity",
  "Professionalism",
  "Attention to detail",
  "Analytical thinking",
  "Conflict management",
  "Fast learner",
  "Database programming",
  "Organizational skills",
  "Data structures and algorithms",
  "Time management",
];

export const devSkills = [
  "C++",
  "CSS",
  "C#",
  "Operating Systems",
  "Linux",
  "Windows",
  "Full-Stack Development",
  "Front-End Development",
  "Back-End Development",
  "Java",
  "JavaScript",
  "SQL",
  "Debugging",
  "Visual Studio",
  "Visual Studio Code",
  "Xcode",
  "PyCharm",
  "Go",
  "Microsoft Azure",
  "API",
  "Github",
  "Git",
  "Ruby",
  "PHP",
  "C",
  "Kotlin",
  "Ruby",
  "Unity",
  "Unreal Engine",
  "Godot",
  "React",
  "Node.js",
  "OpenAI",
  "Html",
  "Python",
  "TypeScript",
  "MongoDB",
  "Vue.js",
  "Bootstrap",
  "Lua",
]

export const desSkills = [
  "Sketch",
  "Figma",
  "Adobe",
  "Adobe PhotoShop",
  "Canva",
  "Fotor",
  "GIMP",
  "Adobe XD",
  "Axure",
  "Adobe Illustrator",
  "Desygner",
  "Coolers",
  "Pixlr",
  "Visme",
  "Webflow",
  "Blender",
  "Procreate",
  "Autodesk Maya",
  "Krita",
  "Cinema 4D",
  "ZBrush",
  "SketchUp",
  "Photo/Video Editing",
  "Photography",
  "Adobe After Effects",
  "Clip Studio Paint",
]

export const peopleTags = [
  "All",
  "Developers",
  "Designers",
  "Other"
]

//Used for profiles and position listings on projects
export const proficiencies = [
  "Full-stack Development",
  "Back-end Development",
  "Front-end Development",
  "Software Development",
  "Cloud Development",
  "Mobile Development",
  "Embedded Systems Development",
  "Game Development",
  "Database Development",
  "PHP Development",
  "Blockchain Development",
  "DevOps Development",
  "AR/VR Development",
  "UI/UX Design",
  "Graphic Design",
  "Web Design",
  "Technical Design",
  "Video Game Design",
  "Product Design",
  "Visual Design",
  "Motion Graphic Design",
  "Packaging Design",
  "Interior Design",
  "Motion graphics Design",
  "Industrial Design",
  "Mentorship",
  "Video Editing",
  "Management",
  "Project Management",
  "Graphic Artist",
  "Instructor",
  "Sound Effects",
  "Marketing",
  "Cyber Security",
  "Web editor",
  "Digital Strategy",
  "Technical Writing",
  "IT"
];

//Used for profiles and projects
export const tools = [
  "C++",
  "CSS",
  "C#",
  "Operating Systems",
  "Linux",
  "Windows",
  "Full-stack development",
  "Java",
  "Javascript",
  "SQL",
  "Debugging",
  "Visual Studio",
  "Visual Studio Code",
  "Xcode",
  "PyCharm",
  "Go",
  "Mircosoft Azure",
  "API",
  "GIthub",
  "Git",
  "Ruby",
  "PHP",
  "C",
  "Kotlin",
  "Ruby",
  "Unity",
  "Sketch",
  "Figma",
  "Adobe",
  "Adobe PhotoShop",
  "Canva",
  "Fotor",
  "GIMP",
  "Adobe XD",
  "Axure",
  "Adobe Illustrator",
  "Desygner",
  "Coolers",
  "Pixlr",
  "Visme",
  "Webflow",
  "Blender",
  "Procreate",
  "Autodesk Maya",
  "Aseprite"
];

//Used for projects and profile preferences
export const projectDetails = [
  "Full-time",
  "Part-time",
  "Flexible",
  "Short-term",
  "Long-term",
  "On-site",
  "Remote",
  "Academic",
  "Personal",
  "Paid",
  "Unpaid",
]

export const majors = [
  "Animation",
  "Computer Engineering",
  "Computing Engineering Technology",
  "Computing Exploration",
  "Computing and Information Technologies",
  "Computer Science",
  "Cybersecurity",
  "3D Digital Design",
  "Electrical Engineering",
  "Electrical Engineering Technology",
  "Engineering Exploration",
  "Engineering Technology Exploration",
  "Film and Animation",
  "Fine Art Photography",
  "Game Arts",
  "Game Design and Development",
  "Graphic Design",
  "Humanities, Computing, and Design",
  "Human-Centered Computing",
  "Illustration",
  "Industrial Design",
  "Industrial Engineering",
  "Motion Picture Science",
  "New Media Design",
  "New Media Interactive Development",
  "Software Engineering",
  "Web and Mobile Computing",
  "Print and Graphic Media Technology",
  "Printmaking",
  "Product Design",
  "Production Option",
  "Robotics and Automation",
  "Robotics and Manufacturing",
  "Robotics",
  "Other",
]

export const genres = [
  "Indie",
  "Abstract",
  "Horror",
  "Sci-Fi",
  "Narrative",
  "Action",
  "Informative",
  "Cute",
  "Fantasy",
  "Comedy",
  "Drama",
  "Coming-of-age",
  "Historical",
  "Romance",
  "Mystery",
  "Slice of Life",
  "Supernatural",
  "Thriller",
  "Adventure",
  "Psychological",
  "Psychological Horror",
  "Sports",
  "Dystopian",
  "Retro",
  "Dark",
  "Realistic",
  "Other",
]